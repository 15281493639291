body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Sen';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
@font-face {
  font-family: 'Sen';
  src: url(../public/Sen-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sen';
  src: url(../public/Sen-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: 'Sen';
  src: url(../public/Sen-ExtraBold.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

.font-bland {
  font-family: 'Open Sans', sans-serif;
}

.login-card {
  min-width: 400px;
}
.firebaseui-idp-list, .firebaseui-tenant-list {
  margin: 0 !important;
}
.accent {background-color: rgb(135, 189, 253);}

.default-font {font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif}


.paypal-button-row:not(.paypal-button-number-0) {
  display: none !important;
}
